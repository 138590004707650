import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { PageEditData, PageTranslation } from 'types/PageEdit';
import { getRequest } from 'utils/request';

interface Props {
  data: PageTranslation;
}

const Slug = (props: Props) => {
  const { data } = props;

  return (
    <>
      <Head>
        <title>{data?.metaTitle}</title>
        <meta name="keywords" content={data?.metaKeywords} />
        <meta name="description" content={data?.metaDescription} />
      </Head>
      <article
        className="custom-inner-wrapper"
        dangerouslySetInnerHTML={{ __html: data?.content }}
      />
    </>
  );
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

export async function getStaticProps(context) {
  const { params, locale = 'en' } = context;
  let data = [];

  try {
    const response = await getRequest('/pages', params);

    if (response.data) {
      data = response.data;
    }
  } catch (error) {
    console.error(error);
  }

  if (!data[0]) {
    return {
      notFound: true,
    };
  }

  let page: PageEditData = data[0];
  let dataSource = page.translations[locale];

  if (!dataSource?.content?.length || dataSource?.content?.length < 2) {
    dataSource = page.translations.en;
  }

  return {
    props: {
      data: dataSource,
      ...(await serverSideTranslations(locale)),
    },
    revalidate: 600,
  };
}

export default Slug;
